<template>
	<div class="table">
		<div class="table-row" v-for="(row, i) in data" :key="i">
			<div class="table-cell" v-for="(cell, j) in row" :key="j">
				<span v-if="!cell" style="color:red">MISSING</span>
				<template v-else>
					<a
						v-if="cell.type == 'sort'"
						href="#"
						@click.prevent="$emit('update:modelValue', cell.sort)"
						:class="[cell.sel ? 'sel' : '', cell.sort.match(/^-/) ? '' : 'reverse']"
						>{{ cell.display }}</a
					>

					<router-link
						v-else-if="cell.type == 'link'"
						:to="cell.to"
						:target="cell.target ? cell.target : ''"
						>{{ cell.display }}</router-link
					>

					<a v-else-if="cell.type == 'link-out'" :href="cell.href" :target="cell.target ? cell.target : ''">{{
						cell.display
					}}</a>

					<template v-else>{{ cell }}</template>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BaseTable',
	props: {
		data: {
			type: Array,
			required: true,
		},
	},
	emits: ['update:modelValue'],
	data() {
		return {}
	},
}
</script>

<style scoped lang="scss">
.table {
	width: 100%;
	display: table;
}
.table-row {
	display: table-row;
}

.table-cell {
	display: table-cell;
	background: #fff;
	padding: 0 0.13rem;
	height: 0.3rem;
	line-height: 0.3rem;
	border: solid 0.01rem $black-10;
}
.table-cell:not(:last-child) {
	border-right: none;
}
.table-row:not(:last-child) .table-cell {
	border-bottom: none;
}

/* Header */
.table-row:first-child .table-cell {
	background: transparent;
	border: none;
	font-weight: 600;
	font-size: 0.13rem;
	text-transform: uppercase;
	letter-spacing: 0.004rem;
}
.table-row:first-child a:not(:hover) {
	color: $black;
}
.table-row:first-child a.sel {
	color: $good;
}
.table-row:first-child a.sel::after {
	content: '\0020\0020\2193'; // Down arrow
	white-space: pre;
}
.table-row:first-child a.sel.reverse::after {
	content: '\0020\0020\2191'; // Up arrow
}
</style>
