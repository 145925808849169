<template>
	<BaseLoadingText v-if="loading" />
	<template v-else>
		<div class="block-wrap">
			<div class="block">
				<div class="item">
					<b>Total:</b> <span>{{ prettyNr(total, $imperial) }}</span>
				</div>
			</div>
		</div>

		<div class="sep"></div>

		<div id="options">
			<FormDropdown id="list" :options="pageSizeDdOptions" v-model="pageSize" />
		</div>

		<BasePagination v-if="pageCount > 1" :pageCount="pageCount" :currentPage="page" />
		<div id="table-wrap">
			<BaseTable :data="tableData" v-model="sort" />
		</div>
		<BasePagination v-if="pageCount > 1" :pageCount="pageCount" :currentPage="page" />
	</template>
</template>

<script>
// Stores
import { useApiStore } from '@/stores/ApiStore'

// Components
import BaseLoadingText from '@/components/BaseLoadingText'
import BasePagination from '@/components/BasePagination'
import BaseTable from '@/components/BaseTable'
import FormDropdown from '@/components/FormDropdown'

// Internal
import { prettyNr } from '@/use/StringMagic'
import { pageSizeDdOptions } from '@/use/General'

export default {
	name: 'AdminInspectEntityLinked',
	components: {
		BaseLoadingText,
		BasePagination,
		BaseTable,
		FormDropdown,
	},
	props: {
		// Passive
		id_username_namePath_wildcard: String,

		// Active
		page: {
			type: [Number, String],
			default: 1,
		},
		entity: {
			type: Object,
			required: true,
		},
		entityType: {
			type: String,
			required: true,
		},
		id_username_namePath: {
			type: String,
			required: true,
		},
	},
	setup() {
		const apiStore = useApiStore()
		const adminInspectApi = apiStore.loadApi('adminInspect')

		return { adminInspectApi, prettyNr, pageSizeDdOptions: pageSizeDdOptions() }
	},
	data() {
		return {
			loading: true,
			rows: [],
			sort: this.$route.query.sort || 'index',
			pageSize: this.$route.query.pageSize || 30, // To avoid error with undefined value during init
			pageCount: 0,
			total: 0,
			dim: false,
			// collectors / followers / following / lists / rooms
			relationType: this.$route.meta.relationType,
		}
	},
	computed: {
		classDim() {
			return this.dim ? 'dim' : ''
		},
		// sort() { // trash
		// 	return this.$route.query.sort ? this.$route.query.sort : 'index'
		// },
		tableData() {
			if (this.relationType == 'rooms') {
				return this.roomTable
			} else {
				return this.peopleTable
			}
		},

		// Table for collectors / followers / following
		peopleTable() {
			// Table content
			const table = this.rows.map(person => {
				return [
					prettyNr(person.index + 1, this.$imperial),
					{
						type: 'link',
						display: person.name,
						to: {
							name: 'AdminInspectEntity',
							params: {
								entityType: 'user',
								id_username_namePath: person.username,
							},
						},
					},
					{
						type: 'link',
						display: 'Profile',
						to: { name: 'User', params: { username: person.username } },
					},
					person.artworkCount ? prettyNr(person.artworkCount, this.$imperial) : '-',
					person.roomCount ? prettyNr(person.roomCount, this.$imperial) : '-',
				]
			})

			// Table header
			table.unshift([
				{
					type: 'sort',
					sort: this.sort.match(/^index$/) ? '-index' : 'index',
					display: 'Index',
					sel: this.sort.match(/^-?index$/),
				},
				{
					type: 'sort',
					sort: this.sort.match(/^name$/) ? '-name' : 'name',
					display: 'Name',
					sel: this.sort.match(/^-?name$/),
				},
				'Profile',
				{
					type: 'sort',
					sort: this.sort.match(/^-artworkCount$/) ? 'artworkCount' : '-artworkCount',
					display: 'Coll.',
					sel: this.sort.match(/^-?artworkCount$/),
				},
				{
					type: 'sort',
					sort: this.sort.match(/^-roomCount$/) ? 'roomCount' : '-roomCount',
					display: 'Rooms',
					sel: this.sort.match(/^-?roomCount$/),
				},
			])
			return table
		},

		// Table for rooms
		roomTable() {
			// Table content
			const table = this.rows.map(room => {
				return [
					prettyNr(room.index + 1, this.$imperial),
					{
						type: 'link',
						display: room.name,
						to: {
							name: 'AdminInspectEntity',
							params: { entityType: 'room', id_username_namePath: room.id },
						},
					},
					{
						type: 'link',
						display: 'Room',
						to: { name: 'Room', params: { id: room.id } },
					},
					room.artworkCount ? this.prettyNr(room.artworkCount, this.$imperial) : '-',
				]
			})
			// Table header
			table.unshift([
				{
					type: 'sort',
					sort: this.sort.match(/^index$/) ? '-index' : 'index',
					display: 'Index',
					sel: this.sort.match(/^-?index$/),
				},
				{
					type: 'sort',
					sort: this.sort.match(/^name$/) ? '-name' : 'name',
					display: 'Name',
					sel: this.sort.match(/^-?name$/),
				},
				'Room',
				{
					type: 'sort',
					sort: this.sort.match(/^-artworkCount$/) ? 'artworkCount' : '-artworkCount',
					display: 'Size',
					sel: this.sort.match(/^-?artworkCount$/),
				},
			])
			return table
		},
		page_sort_pageSize() {
			return this.page + '_' + this.sort + '_' + this.pageSize
		},
	},
	watch: {
		// Note: there's a more elegant way of doing this
		// see Admin › Index › Entity.vue › beforeRouteUpdate
		async page_sort_pageSize(newValue, oldValue) {
			newValue = newValue.split('_')
			oldValue = oldValue.split('_')
			const oldPage = +oldValue[0]
			const oldSort = oldValue[1]
			const oldPageSize = +oldValue[2]
			const newPage = +newValue[0]
			const newSort = newValue[1]
			const newPageSize = +newValue[2]

			if (newPageSize != oldPageSize) {
				// Changing pages size --> jump back tp page 1
				this.$router.replace({
					name: this.$route.name,
					params: { ...this.$route.params, page: 1 },
					query: { ...this.$route.query, pageSize: this.pageSize },
				})
				// When pageSize changes, page will be reset to one,
				// which will trigger another route change, which will
				// take care of reloading artworks
				if (oldPage == 1) this.getLinked()
			} else if (newSort != oldSort) {
				this.$router.replace({
					name: this.$route.name,
					params: { ...this.$route.params, page: 1 },
					query: { ...this.$route.query, sort: newSort },
				})
				if (oldPage == 1) this.getLinked()
			} else if (newPage != oldPage) {
				this.getLinked()
			}
		},
		// This ensures the page is being reloaded
		// when switching between rooms and collectors
		$route(to) {
			if (to.name == 'AdminInspectEntityCollectors' || to.name == 'AdminInspectEntityRooms') {
				this.relationType = to.meta.relationType
				this.getLinked()
			}
		},
	},
	created() {
		this.getLinked()
	},
	methods: {
		getLinked() {
			if (this.relationType.match(/^(collectors|followers|following)$/)) {
				this.getPeople()
			} else if (this.relationType == 'rooms') {
				this.getRooms()
			}
		},

		// Get list of people
		// Collectors, followers, following
		async getPeople() {
			this.dim = true
			const { data } = await this.adminInspectApi.getPeople(
				this.entityType,
				this.relationType,
				this.entity.id,
				this.pageSize,
				this.page,
				this.sort
			)
			const { people, pageCount, total } = data
			this.rows = people
			this.pageCount = pageCount
			this.total = total
			this.dim = false
			this.loading = false
			this.goToPage1()
		},

		// Get list of rooms
		async getRooms() {
			this.dim = true
			const { data } = await this.adminInspectApi.getRooms(
				this.entityType,
				this.entity.id,
				this.pageSize,
				this.page,
				this.sort
			)
			const { rooms, pageCount, total } = data
			this.rows = rooms
			this.pageCount = pageCount
			this.total = total
			this.dim = false
			this.loading = false
			this.goToPage1()
		},

		// When there's pagination, we forward to page 1
		goToPage1() {
			if (!this.$route.params.page && this.total > this.pageSize) {
				this.$route.params.page = 1
				this.$router.replace(this.$route)
			}
		},
	},
}
</script>

<style lang="scss" scoped src="@/assets/css/admin-inspect-entity-info.scss"></style>

<style lang="scss" scoped>
#options {
	margin-bottom: 0.3rem;
}
#options .dropdown {
	display: inline-block;
}

#table-wrap {
	width: 6rem;
	margin-bottom: 0.3rem;
}
#table-wrap:deep() .table-cell:nth-child(1),
#table-wrap:deep() .table-cell:nth-child(3),
#table-wrap:deep() .table-cell:nth-child(4),
#table-wrap:deep() .table-cell:nth-child(5) {
	width: 0.6rem;
}
</style>
